import React, { useEffect, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';

import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { gridHeight } from '../../services/Api';

export const PriceGrid = ({ data, name, config: { names, types } }) => {
    const [gridApi, setGridApi] = useState(null);
    const [rowData, setRowData] = useState([])
    
    const [columnDefs, setColumnDefs] = useState([])

    const onGridReady = (params) => {
        setGridApi(params.api);
        sizeToFit();
        window.onresizable = () => {
            sizeToFit();
            // autoSizeAll()
        }
    };
  
    const sizeToFit = () => {
        if (gridApi) gridApi.sizeColumnsToFit();
    };

    useEffect(() => {
        setRowData(data)
    }, [data])
    
    useEffect(() => {
        const conf = types.map(type => ({ field: type, headerName: type }))
        setColumnDefs([{ field: 'name', headerName: 'Name', flex: 2, minWidth: 130 }, ...conf])
    }, [types])
    
    return (
        <div className="column-center w-full">
            <label className='fs-16 ft-title mb-2'>{name}</label>
            <div className="ag-theme-alpine ag-packed" style={{ height: gridHeight(names.length), width: '99%' }}>
                <AgGridReact rowData={rowData} columnDefs={columnDefs} onGridReady={onGridReady} 
                    defaultColDef={{
                        resizable: true,
                        flex: 1,
                        minWidth: 30,
                        suppressMovable: true,
                        cellStyle: { textAlign: 'center' }
                    }}
                />
            </div>
        </div>
    );
};