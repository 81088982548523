import React, { useEffect, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import moment from 'moment'
import { numberCommaFormatter } from '../../services/Api';

export const InterestGrid = ({ data, name, callback }) => {
    const [gridApi, setGridApi] = useState(null);
    const [rowData, setRowData] = useState([])
    
    const [columnDefs, setColumnDefs] = useState([])

    const onGridReady = (params) => {
        setGridApi(params.api);
        sizeToFit();
        window.onresizable = () => {
            sizeToFit();
        }
    };
  
    const sizeToFit = () => {
        if (gridApi) gridApi.sizeColumnsToFit();
    };

    useEffect(() => {
        if (data) {
            const cellStyle = param => {
                const field = param.colDef.field.replace('diff_', '')
                const days = moment(param.data.date).diff(moment(param.data[field]), 'd')
                const delta = Math.round(param.value/(param.data.openint - param.value)*100/days)
                let bg = '#ffffff'
                if (delta > 20) {
                    bg = '#9cc400'
                } if (delta > 10) {
                    bg = '#c5e1a5'
                } else if (delta > 5) {
                    bg = '#D9E8CB'
                } else if (delta > 0) {
                    bg = '#EDF4E7'
                } else if (delta > -5) {
                    bg = '#f5c1b2'
                } else if (delta > -10) {
                    bg = '#e99771'
                } else if (delta > -20) {
                    bg = '#e5633f'
                }
                return { textAlign: 'center', backgroundColor: bg }
            }
            const cellRenderer = param => <><div className="cell-liner">{numberCommaFormatter(param.value)}</div><div className="cell-liner"><small>({Math.round(param.value/(param.data.openint - param.value)*100)}%)</small></div></>
            const { date } = data[0]
            setColumnDefs([
                { field: 'month', headerName: 'Strip', cellStyle: { textDecoration: 'underline', color: 'blue' }, onCellClicked: param => onClickCallback(param)  },
                { field: 'openint', headerName: date, valueFormatter: param => numberCommaFormatter(param.value) },
                { field: 'diff_lastday', headerName: `Day Δ`, cellRenderer, cellStyle },
                { field: 'diff_lastweek', headerName: `Week Δ`, cellRenderer, cellStyle },
                { field: 'diff_lastmonth', headerName: `Month Δ`, cellRenderer, cellStyle },
            ])
            setRowData(data)
        }
    }, [data])
    
    const onClickCallback = (param) => {
        callback({ name, strip: param.value, data: param.data.data })
    }
    
    return (
        <div className="column-center w-full">
            <div className="ag-theme-alpine ag-packed" style={{ height: 400, width: '99%' }}>
                <AgGridReact rowData={rowData} columnDefs={columnDefs} rowHeight={50} onGridReady={onGridReady} 
                    defaultColDef={{
                        flex: 1,
                        minWidth: 60,
                        suppressMovable: true,
                        cellStyle: { textAlign: 'center' }
                    }}
                />
            </div>
        </div>
    );
};