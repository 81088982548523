import { useState, useEffect, useContext, useRef } from 'react';
import { Backdrop, Box, Button, ButtonGroup, Card, CardHeader, CardContent, Checkbox, Divider, Fade, FormControl, FormHelperText, Grid, IconButton, 
    InputAdornment, InputLabel, List, ListItem, ListItemText, ListSubheader, MenuItem, Modal , OutlinedInput, Select, 
    ToggleButton, ToggleButtonGroup, Toolbar, Tooltip, Typography } from '@mui/material';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles'
import { tooltipClasses } from '@mui/material/Tooltip';
import { AccountTree, AddCircleOutline, CalendarMonth, OilBarrel, RemoveCircleOutline, Save, Settings, Tune } from '@mui/icons-material'
import dayjs from 'dayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { Message, DoubleGrid, GeneralGrid, GeneralChart, palette } from '../../../components'
import { list2, post2 } from '../../../services'
import { DispatchContext } from "../../../reducers";
import useWindow from '../../../hooks/useWindow'
import { FlowWithProvider } from './RefineryDiagram';

const theme = createTheme({
    palette
});

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 370,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 1,
};

const base_crude_desc = (crudes) => {
    const percentage = crudes.reduce((pre, cur) => pre + cur.percentage, 0)
    let name = 'None'
    if (crudes.length === 0) {
        name = 'None'
    } else if (crudes.length === 1) {
        name = crudes[0].name
    } else if (crudes.length === 2) {
        name = `${crudes[0].name} and ${crudes[1].name}`
    } else {
        name = `${crudes.sort((a, b) => b.percentage - a.percentage)[0].name} and ${crudes.length - 1} others`
    }
    return { name, percentage }
}

const WhiteTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        padding: '10px',
        fontSize: '14px',
        color: 'black',
        backgroundColor: '#eeeeee'
    }
}));

export const RefinerySelector = ({ defaultValue, refineries, callback }) => {
    const [refinery, setRefinery] = useState(defaultValue?.id || refineries[0].id);
    const n = refineries.findIndex((item) => item.type === 'Mock')
    const onSelectRefinery = (event) => {
        const id = event.target.value
        setRefinery(id)
        const found = refineries.find((item) => item.id === id)
        callback(found)
    }
    return <ThemeProvider theme={theme}>
        <FormControl fullWidth sx={{ width: 170 }} size="small">
            <InputLabel>Refinery</InputLabel>
            <Select color="dark_blue" value={refinery} onChange={onSelectRefinery} input={<OutlinedInput label="Refinery" sx={{ marginRight: 1 }} />}
                renderValue={(selected) => refineries.find((item) => selected === item.id)?.name}>
                <ListSubheader>Public</ListSubheader>
                {refineries.flatMap((item, idx) => {
                    const mi = <MenuItem key={`key_refinery_selection_${item.id}`} value={item.id}>
                        <ListItemText primary={item.name} />
                    </MenuItem>
                    return idx === n ? [<ListSubheader>Private</ListSubheader>, mi] : mi
                })}
            </Select>
        </FormControl>
    </ThemeProvider>
}

export const InputThroughput = ({ defaultValue, callback }) => {
    const [throughput, setThroughput] = useState(0)
    useEffect(() => {
        setThroughput(defaultValue?.capacity?.cdu || 0)
    }, [defaultValue])
    useEffect(() => {
        callback(throughput)
    }, [throughput])
    return <ThemeProvider theme={theme}>
        {throughput && <FormControl color="dark_blue" fullWidth sx={{ width: 110 }} size="small">
            <InputLabel>Throughput</InputLabel>
            <OutlinedInput type="number" size="small" value={throughput} endAdornment={<InputAdornment position="end">KBD</InputAdornment>}
                onChange={(event) => setThroughput(+event.target.value)} />
        </FormControl>}
    </ThemeProvider>
}

export const UnitConfiguration = ({ defaultValue, callback }) => {
    const [open, setOpen] = useState(false);
    const units = [
        { name: 'cdu', label: 'CDU' }, { name: 'vdu', label: 'VDU' },
        { name: 'isom', label: 'Isomerization', config: 'Isomerate' },
        { name: 'reformer', label: 'Reformer', config: 'Reformate' },
        { name: 'dhc', label: 'DHC', config: 'DHC Product' },
        { name: 'dht', label: 'DHT', config: 'DHT Product' }, 
        { name: 'fcc', label: 'FCC', config: 'FCC Conversion' }, 
        { name: 'coker', label: 'Coker', config: 'Coker Conversion' }
    ]
    const [capacity, setCapacity] = useState({});
    const [config, setConfig] = useState({})
    

    useEffect(() => {
        setCapacity(defaultValue?.capacity || {
            cdu: 250,
            vdu: 100,
            dht: 60,
            dhc: 50,
            fcc: 50,
            reformer: 20,
            isom: 10,
            coker: 50
        })
        setConfig(defaultValue?.config || {
            fcc: 'High',
            coker: 'High',
            dht: '10ppm',
            dhc: 'Diesel',
            isom: '89 RON',
            reformer: '104 RON'
        })
    }, [defaultValue])

    const config_options = { 
        reformer: ['104 RON', '100 RON', '96 RON'],
        isom: ['89 RON', '82 RON'],
        dhc: ['Diesel', 'Kero', 'Naphtha'],
        dht: ['10ppm', '50ppm'],
        fcc: ['Low', 'High', 'Deep'],
        coker: ['Low', 'High']
    }

    useEffect(() => {
        callback({ capacity, config })
    }, [config, capacity])

    const onInput = (event, unit_name) => {
        capacity[unit_name] = +event
        setCapacity({ ...capacity })
    }

    const onSetConfig = (event, unit_name) => {
        config[unit_name] = event
        setConfig({ ...config })
    }

    return <ThemeProvider theme={theme}>
        <WhiteTooltip title="Edit capacity and configuration of each unit">
            <IconButton size="small" color="dark_blue" onClick={() => setOpen(true)}>
                <Tune />
            </IconButton>
        </WhiteTooltip>
        <Modal open={open} onClose={() => setOpen(false)} closeAfterTransition slots={{ backdrop: Backdrop }} slotProps={ { backdrop: { timeout: 500 } } }>
            <Fade in={open}>
                <Box sx={style}>
                    <div className='row-center mt-3 mb-3'>
                        <Typography sx={{ fontSize: '16px', fontWeight: 'bold' }}>Unit Configuration</Typography>
                    </div>
                    <List dense sx={{ width: '100%', bgcolor: 'background.paper' }}>
                        <ListItem key={`key_unit_config_options_cdu_vdu`} sx={{ height: 60, justifyContent: 'center', paddingLeft: 0, paddingRight: 0 }}>
                            {units.slice(0, 2).map((item) => <FormControl key={`key_unit_config_options_cdu_vdu_${item.name}`} fullWidth sx={{ m: 1, width: 150 }} onChange={(event) => onInput(event.target.value, item.name)}>
                                <InputLabel>{item.label + ' Capacity'}</InputLabel>
                                <OutlinedInput type="number" size="small" defaultValue={capacity[item.name]} 
                                    endAdornment={<InputAdornment position="end">KBD</InputAdornment>}/>
                            </FormControl>)}
                        </ListItem>
                        <Divider sx={{ margin: 2 }} variant="middle" />
                        {units.slice(2).map((item) => <ListItem key={`key_unit_config_options_${item.name}`} sx={{ height: 60, justifyContent: 'center', paddingLeft: 0, paddingRight: 0 }}>
                            <FormControl fullWidth sx={{ m: 1, width: 150 }} onChange={(event) => onInput(event.target.value, item.name)}>
                                <InputLabel>{item.label + ' Capacity'}</InputLabel>
                                <OutlinedInput type="number" size="small" defaultValue={capacity[item.name]} 
                                    endAdornment={<InputAdornment position="end">KBD</InputAdornment>}/>
                            </FormControl>
                            <FormControl fullWidth sx={{ m: 1, width: 150 }} onChange={(event) => onInput(event.target.value, item.name)}>
                                <InputLabel>{item.config}</InputLabel>
                                <Select sx={{ height: 40 }} value={config[item.name]} onChange={(event) => onSetConfig(event.target.value, item.name)} input={<OutlinedInput label="Isomerate" sx={{ marginRight: 1 }} />}
                                    renderValue={(selected) => selected}>
                                    {config_options[item.name].map((it) => (
                                        <MenuItem key={`key_unit_config_option_${item.name}_${it}`} value={it}>
                                            <ListItemText primary={it} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </ListItem>)}
                    </List>
                    <div className='row-center mt-3 mb-2'>
                        <Button color="dark_blue" variant='outlined' onClick={() => setOpen(false)}>Close</Button>
                    </div>
                </Box>
            </Fade>
        </Modal>
    </ThemeProvider>
}

export const PeriodConfiguration = ({ defaultValue, callback }) => {
    const [open, setOpen] = useState(false);
    const regions = ['SG', 'AG', 'EU']
    const benchmarks = ['Dubai', 'Dated Brent']
    const benchmark_periods = [0, 1, 2].map((n) => ({ label: dayjs().add(n, 'month').format('MMM YY'), value: n }))
    const product_periods = [0, 1, 2].map((n) => ({ label: dayjs().add(n, 'month').format('MMM YY'), value: n }))
    const [benchmark, setBenchmark] = useState();
    const [benchmarkPeriod, setBenchmarkPeriod] = useState();
    const [region, setRegion] = useState();
    const [productPeriod, setProductPeriod] = useState();
    const [type, setType] = useState('day');
    const [date, setDate] = useState(dayjs());
    const [start, setStart] = useState(dayjs().subtract(1, 'month'));
    const [end, setEnd] = useState(dayjs());

    
    useEffect(() => {
        setBenchmark(defaultValue?.benchmark)
        setBenchmarkPeriod(defaultValue?.benchmark_period)
        setRegion(defaultValue?.region)
        setProductPeriod(defaultValue?.product_period)
    }, [defaultValue])

    useEffect(() => {
        callback({ benchmark, benchmark_period: `_m0${benchmarkPeriod}`, region, product_period: `_m0${productPeriod}`, price_type: type, date: date.format('YYYY-MM-DD'), start: start.format('YYYY-MM-DD'), end: end.format('YYYY-MM-DD') })
    }, [benchmark, benchmarkPeriod, region, productPeriod, type, date, start, end])

    const onSelectBenchmark = (event) => {
        setBenchmark(event.target.value);
        if (event.target.value === 'Dated Brent') {
            setRegion('EU')
        }
    };

    const onSelectBenchmarkPeriod = (event) => {
        setBenchmarkPeriod(event.target.value);
        if (event.target.value > productPeriod) {
            setProductPeriod(event.target.value)
        }
    }
  
    const onSelectRegion = (event) => {
        setRegion(event.target.value);
    }

    const onSelectProductPeriod = (event) => {
        setProductPeriod(event.target.value);
    }
  
    const onSelectType = (event) => {
        setType(event.target.value);
    }

    const onSelectDate = (event) => {
        setDate(event)
    }

    const onSelectStart = (event) => {
        setStart(event)
    }

    const onSelectEnd = (event) => {
        setEnd(event)
    }
    
    return <ThemeProvider theme={theme}>
        <WhiteTooltip title="Edit benchmark and pricing period">
            <IconButton size="small" color="dark_blue" onClick={() => setOpen(true)}>
                <CalendarMonth />
            </IconButton>
        </WhiteTooltip>
        <Modal open={open} onClose={() => setOpen(false)} closeAfterTransition slots={{ backdrop: Backdrop }} slotProps={ { backdrop: { timeout: 500 } } }>
            <Fade in={open}>
                <Box sx={style}>
                    <div className='row-center mt-3 mb-3'>
                        <Typography sx={{ fontSize: '16px', fontWeight: 'bold' }}>Benchmark & Pricing Period</Typography>
                    </div>
                    <List dense sx={{ width: '100%', bgcolor: 'background.paper' }}>
                        <ListItem key={`key_period_config_0`} sx={{ height: 60, justifyContent: 'center', paddingLeft: 0, paddingRight: 0 }}>
                            <FormControl fullWidth sx={{ m: 1, width: 150 }} size="small">
                                <InputLabel>Benchmark</InputLabel>
                                <Select value={benchmark} onChange={onSelectBenchmark} input={<OutlinedInput label="Benchmark" sx={{ marginRight: 1 }} />}
                                    renderValue={(selected) => selected}>
                                    {benchmarks.map((item) => (
                                        <MenuItem key={`key_period_config_benchmark_${item}`} value={item}>
                                            <ListItemText primary={item} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <FormControl fullWidth sx={{ m: 1, width: 150 }} size="small">
                                <InputLabel>Benchmark Period</InputLabel>
                                <Select value={benchmarkPeriod} onChange={onSelectBenchmarkPeriod} input={<OutlinedInput label="Benchmark Period" sx={{ marginRight: 1 }} />}
                                    renderValue={(selected) => dayjs().add(selected, 'month').format('MMM YY')}>
                                    {benchmark_periods.map(({ label, value }) => (
                                        <MenuItem key={`key_period_config_benchmark_period_${value}`} value={value}>
                                            <ListItemText primary={label} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </ListItem>
                        <ListItem key={`key_period_config_1`} sx={{ height: 60, justifyContent: 'center', paddingLeft: 0, paddingRight: 0 }}>
                            <FormControl fullWidth sx={{ m: 1, width: 150 }} size="small">
                                <InputLabel>Product Region</InputLabel>
                                <Select value={region} onChange={onSelectRegion} input={<OutlinedInput label="Region" sx={{ marginRight: 1 }} />}
                                    renderValue={(selected) => selected}>
                                    {regions.map((item) => (
                                        <MenuItem key={`key_period_config_region_${item}`} value={item}>
                                            <ListItemText primary={item} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <FormControl fullWidth sx={{ m: 1, width: 150 }} size="small">
                                <InputLabel>Product Period</InputLabel>
                                <Select value={productPeriod} onChange={onSelectProductPeriod} input={<OutlinedInput label="Product Period" sx={{ marginRight: 1 }} />}
                                    renderValue={(selected) => dayjs().add(selected, 'month').format('MMM YY')}>
                                    {product_periods.map(({ label, value }) => (
                                        <MenuItem key={`key_period_config_product_period_${value}`} disabled={value < benchmarkPeriod} value={value}>
                                            <ListItemText primary={label} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </ListItem>
                        <Divider sx={{ margin: 2 }} variant="middle" />
                        <ListItem key={`key_period_config_2`} sx={{ height: 60, justifyContent: 'center', paddingLeft: 0, paddingRight: 0 }}>
                            <FormControl sx={{ m: 1 }} size="small">
                                <ToggleButtonGroup color="dark_blue" value={type} exclusive onChange={onSelectType} >
                                    <ToggleButton value="day">Single Day</ToggleButton>
                                    <ToggleButton value="range">Day Range</ToggleButton>
                                </ToggleButtonGroup>
                            </FormControl>
                        </ListItem>
                        <ListItem key={`key_period_config_3`} sx={{ height: 60, justifyContent: 'start', paddingLeft: 0, paddingRight: 0, marginBottom: 2 }}>
                            <FormControl sx={{ m: 1, width: 160 }} size="small">
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DemoContainer components={['DatePicker']}>
                                        <DatePicker disabled={type === 'range'} className="nw-150" sx={{ width: 150 }} label="Date" defaultValue={date} disableFuture orientation="portrait" onChange={onSelectDate} />
                                    </DemoContainer>
                                </LocalizationProvider>
                            </FormControl>
                        </ListItem>
                        <ListItem key={`key_period_config_4`} sx={{ height: 60, justifyContent: 'center', paddingLeft: 0, paddingRight: 0 }}>
                            <FormControl sx={{ m: 1, width: 160 }} size="small">
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DemoContainer components={['DatePicker']}>
                                    <DatePicker disabled={type === 'day'} className="nw-150" sx={{ width: 150 }} label="Start" defaultValue={start} disableFuture orientation="portrait" onChange={onSelectStart} />
                                </DemoContainer>
                                </LocalizationProvider>
                            </FormControl>
                            <FormControl sx={{ m: 1, width: 160 }} size="small">
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DemoContainer components={['DatePicker']}>
                                    <DatePicker disabled={type === 'day'} className="nw-150" sx={{ width: 150 }} label="End" defaultValue={end} disableFuture orientation="portrait" onChange={onSelectEnd} />
                                </DemoContainer>
                                </LocalizationProvider>
                            </FormControl>
                        </ListItem>
                    </List>
                    <div className='row-center mt-3 mb-2'>
                        <Button color="dark_blue" variant='outlined' onClick={() => setOpen(false)}>Close</Button>
                    </div>
                </Box>
            </Fade>
        </Modal>
    </ThemeProvider>
}

export const CrudeSelector = ({ title = 'Crude Base', width=180, bg='dark_blue', options: opts, defaultValue = [], changeValue = null, callback }) => {
    const options = opts.map(({ name }) => name)
    const [open, setOpen] = useState(false);
    const [selected, setSelected] = useState([]);
    const [desc, setDesc] = useState({ name: 'None', percentage: 0 })
    const [type, setType] = useState('Load Flows');

    useEffect(() => {
        if (changeValue) {
            setSelected([])
            setType('Load Flows')
        }
    }, [changeValue])

    useEffect(() => {
        const desc_ = base_crude_desc(selected)
        setDesc(desc_)
        callback(selected);
    }, [selected]);
    
    const handleChange = (event) => {
        const { target: { value } } = event;
        const changedName = value.find((v) => typeof v === 'string')
        const exist = selected.find(({ name }) => name === changedName)
        if (exist) {
            setSelected(selected.filter(({ name }) => name !== changedName))
        } else {
            setSelected([...selected, { name: changedName, percentage: 0 }])
        }
    };
  
    const onInput = (event, crude_name) => {
        selected.find((crude) => crude.name === crude_name).percentage = +event
        setSelected([...selected])
    }

    const onRemove = (crude) => {
        const idx = selected.findIndex((item) => item.name === crude.name)
        selected.splice(idx, 1)
        setSelected([...selected])
    }

    const toggleType = () => {
        if (type === 'Load Flows') {
            setType('Clear')
            setSelected(defaultValue.map(({ name, percentage }) => ({ name, percentage })))
        } else {
            setType('Load Flows')
            setSelected([])
        }
    }

    return <ThemeProvider theme={theme}>
        <WhiteTooltip title={`Select ${title} and set percentage`}>
            <Card sx={{ width }}>
                <CardHeader subheader={<Typography sx={{ color: '#ffffff' }}>{title}</Typography>} sx={{ backgroundColor: palette[bg].main }}
                  action={
                    <IconButton size="small" sx={{ color: '#ffffff' }} onClick={() => setOpen(true)}>
                        <Settings />
                    </IconButton>
                  }>
                </CardHeader>
                <CardContent>
                    <Typography variant="subtitle2" sx={{ width: '100%' }}>{desc.name}</Typography>
                    <Typography variant="subtitle2" sx={{ }}>Total: {desc.percentage}%</Typography>
                </CardContent>
            </Card>
        </WhiteTooltip>
        <Modal open={open} onClose={() => setOpen(false)} closeAfterTransition slots={{ backdrop: Backdrop }} slotProps={ { backdrop: { timeout: 500 } } }>
            <Fade in={open}>
                <Box sx={style}>
                    <div className='row-between mt-3 mb-3'>
                        <Typography sx={{ fontSize: '16px', fontWeight: 'bold', marginLeft: '10px' }}>{title}</Typography>
                        {defaultValue?.length > 0 ? <Button variant='outlined' sx={{ height: 25, width: 130, marginRight: '10px' }} onClick={toggleType}>{type}</Button>
                        : <></>}
                    </div>
                    <FormControl fullWidth sx={{ p: 1, width: '100%' }} size="small">
                        <InputLabel>Crude</InputLabel>
                        <Select multiple value={selected} onChange={handleChange} input={<OutlinedInput label="Crude" sx={{ width: 320 }} />} displayEmpty={true}
                            renderValue={(selected) => selected.length > 1 ? `${selected.length} selected` : selected[0]?.name}>
                            {options.map((option) => (
                                <MenuItem key={`key_crude_selector_assays_${title}_${option}`} value={option}>
                                <Checkbox checked={selected.some((s) => s.name === option)} />
                                <ListItemText primary={option} />
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <List dense sx={{ width: 329, bgcolor: 'background.paper', marginLeft: 2 }}>
                        {selected.map((crude) => <ListItem key={`key_crude_selector_options_${crude.name}`} sx={{ height: 42 }}
                            secondaryAction={<FormControl fullWidth sx={{ m: 1, width: 120, marginRight: 0 }} onChange={(event) => onInput(event.target.value, crude.name)}>
                                <OutlinedInput type="number" size="small" defaultValue={crude.percentage} endAdornment={<InputAdornment position="end">%</InputAdornment>} />
                            </FormControl>} disablePadding>
                                <IconButton sx={{ width: '5px', height: '5px', marginRight: 2 }} color="red" onClick={() => onRemove(crude)} >
                                    <RemoveCircleOutline />
                                </IconButton>
                                <Typography variant="caption">{crude.name}</Typography>
                            </ListItem>
                        )}
                    </List>
                    <div className='row-center mt-3 mb-2'>
                        <Button color="dark_blue" variant='outlined' onClick={() => setOpen(false)}>Close</Button>
                    </div>
                </Box>
            </Fade>
        </Modal>
    </ThemeProvider>
}

export const SaveRefinery = ({ capacity, period, properties, crudes, callback }) => {
    const dispatch = useContext(DispatchContext)
    const [open, setOpen] = useState(false);
    const [name, setName] = useState('');
    const [error, setError] = useState(false);
    const [helperText, setHelperText] = useState('');
    const [openChild, setOpenChild] = useState(false);
    const units = [
        { name: 'cdu', label: 'CDU' }, { name: 'vdu', label: 'VDU' },
        { name: 'isom', label: 'Isomerization', config: 'Isomerate' },
        { name: 'reformer', label: 'Reformer', config: 'Reformate' },
        { name: 'dhc', label: 'DHC', config: 'DHC Product' },
        { name: 'dht', label: 'DHT', config: 'DHT Product' }, 
        { name: 'fcc', label: 'FCC', config: 'FCC Conversion' }, 
        { name: 'coker', label: 'Coker', config: 'Coker Conversion' }
    ]

    const onInputName = (event) => {
        setName(event.target.value)
        if (event.target.value) {
            setError(false)
            setHelperText('')
        }
    }

    const onClose = () => {
        setHelperText('')
        setError(false)
        setOpen(false)
        setName('')
    }

    const onChildCallback = async (event) => {
        if (event === 'delete') {
            dispatch({ type: 'spin on' })
            const { data: { success, message } } = await post2('refinery_model/delete_refinery', { name })
            dispatch({ type: 'spin off' })
            setOpenChild(false)
            if (success) {
                callback(message)
                onClose()
            } else {
                setHelperText(message)
                setError(true)
            }
        } else if (event === 'overwrite') {
            dispatch({ type: 'spin on' })
            const { data: { success, message } } = await post2('refinery_model/save_refinery', { name, capacity, period, properties, crudes, overwrite: true })
            dispatch({ type: 'spin off' })
            setOpenChild(false)
            if (success) {
                callback(message)
                onClose()
            } else {
                setHelperText(message)
                setError(true)
            }
        } else {
            setOpenChild(false)
        }
    }

    const onSave = async () => {
        if (!name) {
            setError(true)
            setHelperText('Please input name')
        } else {
            dispatch({ type: 'spin on' })
            const { data: { success, message } } = await post2('refinery_model/save_refinery', { name, capacity, period, properties, crudes })
            dispatch({ type: 'spin off' })
            if (success) {
                callback(message)
                onClose()
            } else if (message === 'Refinery already exists') {
                setOpenChild(true)
            } else {
                setHelperText(message)
                setError(true)
            }
        }
    }

    return <ThemeProvider theme={theme}>
        <WhiteTooltip title="Save corrent configuration">
            <IconButton color="dark_green" onClick={() => setOpen(true)} >
                <Save />
            </IconButton>
        </WhiteTooltip>
        <Modal open={open} onClose={() => setOpen(false)} closeAfterTransition
            sx={{ position:'absolute', height:'80%', top:'10%', width: '90%', left: '5%', overflow:'scroll', display:'block' }}>
                <Box component="form" noValidate sx={{ bgcolor: 'background.paper', border: '2px solid #000', p: 1 }}>
                    <Typography variant="h6" sx={{ textAlign: 'center', fontWeight: 'bold', marginLeft: 2 }}>Save Refinery</Typography>
                    <div className='row-between mb-3'>
                        <FormControl fullWidth sx={{ m: 1, width: 150 }}>
                            <InputLabel>Name</InputLabel>
                            <OutlinedInput error={error} type="string" size="small" value={name} onChange={onInputName} />
                            <FormHelperText error={error} >{helperText}</FormHelperText>
                        </FormControl>
                        <ButtonGroup variant="contained">
                            <Button color="success" size="small" sx={{ width: 70 }} variant='contained' onClick={onSave}>Save</Button>
                            <Button color="dark_blue" size="small" sx={{ width: 70 }} variant='outlined' onClick={onClose}>Cancel</Button>
                        </ButtonGroup>
                    </div>
                    <Grid container spacing={2}>
                        <Grid item sm={12} md={6} lg={4}>
                            <Typography variant="h6" sx={{ textAlign: 'center', }}>Unit Configuration</Typography>
                            <List dense sx={{ width: '100%', bgcolor: 'background.paper' }}>
                                <ListItem key={`key_save_refinery_capacity_options_cdu_vdu`} sx={{ height: 60, justifyContent: 'center', paddingLeft: 0, paddingRight: 0 }}>
                                    {units.slice(0, 2).map((item) => <FormControl key={`key_unit_config_options_cdu_vdu_${item.name}`} fullWidth sx={{ m: 1, width: 150 }}>
                                        <InputLabel>{item.label + ' Capacity'}</InputLabel>
                                        <OutlinedInput disabled type="number" size="small" defaultValue={capacity[item.name]} 
                                            endAdornment={<InputAdornment position="end">KBD</InputAdornment>}/>
                                    </FormControl>)}
                                </ListItem>
                                <Divider sx={{ margin: 2 }} variant="middle" />
                                {units.slice(2).map((item) => <ListItem key={`key_save_refinery_capacity_options_${item.name}`} sx={{ height: 60, justifyContent: 'center', paddingLeft: 0, paddingRight: 0 }}>
                                    <FormControl fullWidth sx={{ m: 1, width: 150 }}>
                                        <InputLabel>{item.label + ' Capacity'}</InputLabel>
                                        <OutlinedInput disabled type="number" size="small" defaultValue={capacity[item.name]} 
                                            endAdornment={<InputAdornment position="end">KBD</InputAdornment>}/>
                                    </FormControl>
                                    <FormControl fullWidth sx={{ m: 1, width: 150 }}>
                                        <InputLabel>{item.config}</InputLabel>
                                        <Select disabled sx={{ height: 40 }} value={properties[item.name]} input={<OutlinedInput label="Isomerate" sx={{ marginRight: 1 }} />}
                                            renderValue={(selected) => selected}>
                                        </Select>
                                    </FormControl>
                                </ListItem>)}
                            </List>
                        </Grid>
                        <Grid item sm={12} md={6} lg={4}>
                            <Typography variant="h6" sx={{ textAlign: 'center', }}>Pricing Period</Typography>
                            <List dense sx={{ width: '100%', bgcolor: 'background.paper' }}>
                                <ListItem key={`key_save_refinery_period_0`} sx={{ height: 60, justifyContent: 'center', paddingLeft: 0, paddingRight: 0 }}>
                                    <FormControl fullWidth sx={{ m: 1, width: 150 }} size="small">
                                        <InputLabel>Benchmark</InputLabel>
                                        <Select disabled value={period.benchmark} input={<OutlinedInput label="Benchmark" sx={{ marginRight: 1 }} />}
                                            renderValue={(selected) => selected}>
                                        </Select>
                                    </FormControl>
                                    <FormControl fullWidth sx={{ m: 1, width: 150 }} size="small">
                                        <InputLabel>Benchmark Period</InputLabel>
                                        <Select disabled value={period.benchmark_period.slice(-1)} input={<OutlinedInput label="Benchmark Period" sx={{ marginRight: 1 }} />}
                                            renderValue={(selected) => dayjs().add(selected, 'month').format('MMM YY')}>
                                        </Select>
                                    </FormControl>
                                </ListItem>
                                <ListItem key={`key_save_refinery_period_1`} sx={{ height: 60, justifyContent: 'center', paddingLeft: 0, paddingRight: 0 }}>
                                    <FormControl fullWidth sx={{ m: 1, width: 150 }} size="small">
                                        <InputLabel>Product Region</InputLabel>
                                        <Select disabled value={period.region} input={<OutlinedInput label="Region" sx={{ marginRight: 1 }} />}
                                            renderValue={(selected) => selected}>
                                        </Select>
                                    </FormControl>
                                    <FormControl fullWidth sx={{ m: 1, width: 150 }} size="small">
                                        <InputLabel>Product Period</InputLabel>
                                        <Select disabled value={period.product_period.slice(-1)} input={<OutlinedInput label="Product Period" sx={{ marginRight: 1 }} />}
                                            renderValue={(selected) => dayjs().add(selected, 'month').format('MMM YY')}>
                                        </Select>
                                    </FormControl>
                                </ListItem>
                            </List>
                        </Grid>
                        <Grid item sm={12} md={6} lg={4}>
                            <Typography variant="h6" sx={{ textAlign: 'center', }}>Crude Base</Typography>
                            <List dense sx={{ width: '100%', bgcolor: 'background.paper' }}>
                                {crudes.map((crude, idx) => 
                                    <ListItem key={`key_save_refinery_crude_${idx}`} sx={{ height: 60, justifyContent: 'center', paddingLeft: 0, paddingRight: 0 }}>
                                        <FormControl fullWidth sx={{ m: 1, width: 150 }} size="small">
                                            <InputLabel>Crude</InputLabel>
                                            <Select disabled value={crude.name} input={<OutlinedInput label="Crude" sx={{ marginRight: 1 }} />}
                                                renderValue={(selected) => selected}>
                                            </Select>
                                        </FormControl>
                                        <FormControl fullWidth sx={{ m: 1, width: 150 }} size="small">
                                            <InputLabel>Percentage</InputLabel>
                                            <Select disabled value={crude.percentage} input={<OutlinedInput label="Percentage" sx={{ marginRight: 1 }} />}
                                                renderValue={(selected) => `${selected}%`}>
                                            </Select>
                                        </FormControl>
                                    </ListItem>
                                )}
                            </List>
                        </Grid>
                    </Grid>
                    <SaveRefineryChild name={name} open={openChild} callback={onChildCallback} />
                </Box>
        </Modal>
    </ThemeProvider>
}

export const SaveRefineryChild = ({ name, open, callback }) => {
  
    return <ThemeProvider theme={theme}>
        <Modal open={open} >
            <Box sx={{ ...style }}>
                <Typography variant="h6">Duplicated Refinery Name</Typography>
                <Typography className="mt-3 mb-3" variant="caption">Refinery with name "{name}" already exists, what would you like to proceed?</Typography>
                <div className='row-between mt-3'>
                    <Button size="small" variant="contained" color="error" onClick={() => callback('delete')}>Delete</Button>
                    <Button size="small" variant="contained" color="success" onClick={() => callback('overwrite')}>Overwrite</Button>
                    <Button size="small" color="dark_gray" onClick={() => callback('close')}>Cancel</Button>
                </div>
            </Box>
        </Modal>
    </ThemeProvider>
}

const AssayTable = ({ assay }) => {
    const colDef1 = [
        { field: 'field', headerName: '', cellStyle: { justifyContent: 'left' } }, 
        { field: 'lnap', headerName: 'Lnap', valueFormatter: 'TwoDecimal' }, 
        { field: 'hnap', headerName: 'Hnap', valueFormatter: 'TwoDecimal' }, 
        { field: 'kero', headerName: 'Kero', valueFormatter: 'TwoDecimal' }, 
        { field: 'dsl', headerName: 'Diesel', valueFormatter: 'TwoDecimal' }, 
        { field: 'ar', headerName: 'AR', valueFormatter: 'TwoDecimal' }, 
        { field: 'lvgo', headerName: 'LVGO', valueFormatter: 'TwoDecimal' }, 
        { field: 'hvgo', headerName: 'HVGO', valueFormatter: 'TwoDecimal' }, 
        { field: 'vr', headerName: 'VR', valueFormatter: 'TwoDecimal' } 
    ]
    const cy = Object.keys(assay).filter((key) => key.startsWith('cy_')).reduce((pre, key) => ({ ...pre, [key.replace('cy_', '')]: assay[key] }), { field: 'Cut Yield (wt%)' })
    const sul = Object.keys(assay).filter((key) => key.startsWith('sul_')).reduce((pre, key) => ({ ...pre, [key.replace('sul_', '')]: assay[key] }), { field: 'Sulphur (wt%)' })
    const sg = Object.keys(assay).filter((key) => key.startsWith('sg_')).reduce((pre, key) => ({ ...pre, [key.replace('sg_', '')]: assay[key] }), { field: 'Specific Gravity' })
    const api = Object.keys(assay).filter((key) => key.startsWith('sg_')).reduce((pre, key) => ({ ...pre, [key.replace('sg_', '')]: +(141.5/assay[key]-131.5).toFixed(2) }), { field: 'API' })
    
    const level_class = (level) => ['Extra Light', 'Light', 'Low'].includes(level) ? 'bg-green0' : ['Medium'].includes(level) ? 'bg-grey' : 'bg-red_darker'
    return <div className='mb-6 w-95p'>
        <div className='row-between mb-3'>
            <div className='row-left'>
                <div className='row-left'>
                    <OilBarrel color='primary' size='large' />
                    <div className='ml-1'>{assay?.name}</div>
                </div>
                <div className='row ml-6'>
                    <div className={`circle-30 white ${level_class(assay?.crude_type)}`}>{assay?.crude_type ? assay.crude_type === 'Extra Light' ? 'XL' : assay?.crude_type.slice(0, 1) : 'NA'}</div>
                    <div className='ml-1 fs-12'>
                        <div>API</div>
                        <div>{assay?.api.toFixed(0)}</div>
                    </div>
                </div>
                <div className='row ml-2'>
                    <div className={`circle-30 white ${level_class(assay?.sulphur_type)}`}>{assay?.sulphur_type ? assay.sulphur_type.slice(0, 1) : ''}</div>
                    <div className='ml-1 fs-12'>
                        <div>SUL</div>
                        <div>{assay?.sul.toFixed(2)}</div>
                    </div>
                </div>
            </div>
            <div className='ml-2 row-center'>
                <img loading="lazy" width="30" src={assay?.src} alt="" />
                <label className='ml-1'>{assay?.country}</label>
            </div>
        </div>
        <GeneralGrid param={{ data: [cy, sul, sg, api], colDef: colDef1 }} callback={() => null} />
    </div>
}

export const RefineryCrudeEvaluation = () => {

    const size = useWindow()
    const dispatch = useContext(DispatchContext)
    const childRef = useRef()
    const [refineries, setRefineries] = useState([])
    const [assays, setAssays] = useState([])
    const [crudes, setCrudes] = useState([])
    const [throughput, setThroughput] = useState(0)
    const [capacity, setCapacity] = useState()
    const [properties, setProperties] = useState()
    const [period, setPeriod] = useState()
    const [params, setParams] = useState([])
    const [targetCrudes, setTargetCrudes] = useState([[], []])
    const [refinery, setRefinery] = useState(refineries[0])
    const [toggles, setToggles] = useState()
    const [diagramData, setDiagramData] = useState()
    const [selectedDiagramData, setSelectedDiagramData] = useState()
    const [selectedAssay, setSelectedAssay] = useState()
    const [openDiagram, setOpenDiagram] = useState(false)
    const [detailView, setDetailView] = useState('assay')

    const init = async () => {
        dispatch({ type: 'spin on' })
        const [refineries_, assays_] = await Promise.all([
            list2('refinery_model', 'refineries'),
            list2('refinery_model', 'assays')
        ])
        dispatch({ type: 'spin off' })
        setRefineries(refineries_)
        setAssays(assays_)
        setRefinery(refineries_[0])
    }

    useEffect(() => {
        init()
    }, [])

    useEffect(() => {
        if (diagramData) sessionStorage.setItem("diagramData", JSON.stringify(diagramData))
    }, [diagramData])

    const onSelectRefinery = (event) => {
        setRefinery(event)
        if (event.crudes) {
            setCrudes(event.crudes)
        }
    }

    const onInputThroughput = (event) => {
        setThroughput(event)
    }

    const onSelectCrudes = (event) => {
        setCrudes(event)
    }

    const onSelectTargetCrudes = (event, idx) => {
        const new_target_crudes = [...targetCrudes]
        new_target_crudes[idx] = event
        setTargetCrudes(new_target_crudes)
    }

    const onUnitConfiguration = (event) => {
        setCapacity(event.capacity)
        setProperties(event.config)
    }

    const onPeriodConfiguration = (event) => {
        setPeriod(event)
    }

    const onSelectToggle = (event, key) => {
        toggles[key] = event.target.value
        setToggles({ ...toggles })
    }

    const onSaveRefinery = (message) => {
        childRef.current.showSuccess(message)
        init()
    }

    const asignDiagram = (name) => {
        const dd = JSON.parse(sessionStorage.getItem('diagramData'))
        const data = dd[name]
        console.log('data', data)
        setSelectedDiagramData(dd[name])
    }

    const onClickGridCell = (event, key) => {
        const valid_keys = ['key_single_grid_netback_comparison', 'key_single_grid_yield_comparison', 'key_range_grid_volume_comparison']
        if (!valid_keys.includes(key)) return
        const name = event.data.Target
        asignDiagram(name)
        const assay = assays.find((assay) => assay.name === name)
        setSelectedAssay(assay)
        setOpenDiagram(true)
        setDetailView('assay')
    }

    const onSelectDetailView = (event) => {
        setDetailView(event)
    }

    const onCloseDetail = () => {
        setSelectedDiagramData(null)
        setSelectedAssay(null)
        setOpenDiagram(false)
        setDetailView('assay')
    }

    const onValidatePercentage = () => {
        const total_base = crudes.reduce((acc, cur) => acc + cur.percentage, 0)
        const failed = []
        targetCrudes.forEach((t_crudes, idx) => {
            const total_target = t_crudes.reduce((acc, cur) => acc + cur.percentage, 0)
            if (Math.abs(total_base + total_target - 100) > 0.1) {
                failed.push(idx + 1)
            }
        })
        if (failed.length > 0) {
            return { success: false, message: `Total percentage of Crude Target ${failed.join(', ')} must be 100%` }
        } else {
            return { success: true }
        }
    }

    const onValidatePercentageRank = () => {
        const total_base = crudes.reduce((acc, cur) => acc + cur.percentage, 0)
        if (total_base - 100 > 0.1) {
            return { success: false, message: `Total percentage of Crude Base is already 100%` }
        } else {
            return { success: true }
        }
    }

    const onRun = async () => {
        const validate = onValidatePercentage()
        if (!validate.success) {
            childRef.current.showError(validate.message)
            return
        }
        setParams([])
        setToggles(null)
        dispatch({ type: 'spin on' })
        const { data: { result, toggles, diagrams } } = await post2('refinery_model/run_comparison', { crudes, throughput, targetCrudes, capacity, properties, period })
        dispatch({ type: 'spin off' })
        setToggles(toggles)
        setParams(result)
        setDiagramData(diagrams)
    }

    const onRank = async () => {
        const validate = onValidatePercentageRank()
        if (!validate.success) {
            childRef.current.showError(validate.message)
            return
        }
        setParams([])
        setToggles(null)
        dispatch({ type: 'spin on' })
        const { data: { result, toggles, diagrams } } = await post2('refinery_model/run_rank', { crudes, throughput, capacity, properties, period })
        dispatch({ type: 'spin off' })
        setToggles(toggles)
        setParams(result)
        setDiagramData(diagrams)
    }

    return <ThemeProvider theme={theme}>
        <div className="p-2 mb-6">
            <Message ref={childRef} />
            {refineries.length > 0 && <RefinerySelector defaultValue={refinery} refineries={refineries} callback={onSelectRefinery} />}
            <InputThroughput defaultValue={refinery} callback={onInputThroughput} />
            <UnitConfiguration defaultValue={refinery} callback={onUnitConfiguration} />
            <PeriodConfiguration defaultValue={refinery} callback={onPeriodConfiguration} />
            {capacity && period && properties && <SaveRefinery capacity={capacity} period={period} properties={properties} crudes={crudes} callback={onSaveRefinery} />}
            <Divider sx={{ margin: 2 }} variant="middle" />
            <Grid container spacing={1} sx={{ display: 'flex', justifyContent: 'start' }} >
                <Grid key={`key_base_crude_grid`} item xs={12} sm={4} md={3} lg={2} display="flex" justifyContent="start">
                {assays.length > 0 && <CrudeSelector title={'Crude Base'} bg='dark_green' defaultValue={refinery?.crudes} changeValue={refinery?.crudes} options={assays} callback={onSelectCrudes} />}
                </Grid>
                {targetCrudes.map((t_crudes, idx) => <Grid key={`key_target_crude_grid_${idx}`} item xs={6} sm={4} md={3} lg={2} display="flex" justifyContent="start">
                    {assays.length > 0 && <CrudeSelector title={`Crude Target ${idx + 1}`} defaultValue={t_crudes} options={assays} callback={(event) => onSelectTargetCrudes(event, idx)} />}
                </Grid>)}
                <Grid key={`key_target_crude_grid_buttons`} item xs={12} sm={6} md={4} lg={3} display="flex" alignItems="center">
                    <IconButton color="dark_green" disabled={targetCrudes.length > 9} onClick={() => setTargetCrudes([...targetCrudes, []])} >
                        <AddCircleOutline />
                    </IconButton>
                    <IconButton color="red" disabled={targetCrudes.length < 3} onClick={() => setTargetCrudes(targetCrudes.slice(0, -1))} >
                        <RemoveCircleOutline />
                    </IconButton>
                </Grid>
            </Grid>
            <div className='w-full row-center mt-3 mb-2'>
                <div className='w-300 row-between'>
                    <WhiteTooltip title="Select two or more target crudes, combine each with the base crude, then evaluate their economic value">
                        <Button color="dark_blue" variant="contained" sx={{ color: "#ffffff", width: '120px' }} onClick={onRun}>Run</Button>
                    </WhiteTooltip>
                    {period?.price_type === 'day' && <WhiteTooltip title="Auto run through every crude, combine each with the base crude, then rank their economic value">
                        <Button color="purple" variant="outlined" sx={{ width: '120px' }} onClick={onRank}>Auto Rank</Button>
                    </WhiteTooltip>}
                </div>
            </div>
            <Divider sx={{ margin: 2 }} variant="middle" />
            <Grid container spacing={1} sx={{ display: 'flex', justifyContent: 'center' }} >
                {toggles && params.map((param) => <Grid key={param.key} item xs={12} sm={12} md={12} lg={param.long ? 12 : 6} display="flex" justifyContent="center">
                    <div className='mt-3 w-full'>
                        {['grid_toggle'].includes(param.type) ? <>
                            <div className={(param.hasSubtitle ? '' : 'mb-2 ') + 'ml-1 row-between'}>
                                <Typography variant="h6" sx={{ fontWeight: 'bold' }}>{param.title}</Typography>
                                <ToggleButtonGroup sx={{ height: 30 }} color="dark_blue" value={toggles[param.key]} exclusive onChange={(event) => onSelectToggle(event, param.key)} >
                                    {param.fields.map((field, idx) => <ToggleButton sx={{ width: 40 }} value={field}>{param.labels[idx]}</ToggleButton>)}
                                </ToggleButtonGroup>
                            </div>
                            {param.hasSubtitle && <div className='ml-1 row'><Typography variant="subtitle2">{param.subtitle || ''}&nbsp;</Typography></div>}
                            {param.double ? <DoubleGrid param={param.params[toggles[param.key]]} callback={(event) => onClickGridCell(event, param.key)} />
                        : <GeneralGrid param={param.params[toggles[param.key]]} callback={(event) => onClickGridCell(event, param.key)} />}
                        </>
                        :
                        <>
                            <div className={param.hasSubtitle ? '' : 'mb-2 ' + 'ml-1'}>
                                <Typography variant="h6" sx={{ fontWeight: 'bold' }}>{param.title || ''}</Typography>
                            </div>
                            {param.hasSubtitle && <div className='ml-1 row'><Typography variant="subtitle2">{param.subtitle || ''}&nbsp;</Typography></div>}
                            {param.type === 'grid' && <GeneralGrid param={param.params} callback={(event) => onClickGridCell(event, param.key)} />}
                            {param.type === 'chart' && <GeneralChart param={param.params} callback={() => {}} />}
                        </>}
                    </div>
                </Grid>)}
            </Grid>
            <Modal open={openDiagram} onClose={() => setOpenDiagram(false)} closeAfterTransition
                sx={{ position:'absolute', top: 10, left: 10, height: size.height-20, width: size.width-20, overflow:'scroll', display:'block' }}>
                <Box noValidate sx={{ bgcolor: 'background.paper', border: '2px solid #000' }}>
                    <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <ToggleButtonGroup color="dark_blue" size='small' value={detailView} exclusive >
                            <ToggleButton value="assay" onClick={() => onSelectDetailView('assay')}><OilBarrel /><div className='ml-1' >Assay</div></ToggleButton>
                            <ToggleButton value="flow" onClick={() => onSelectDetailView('flow')}><AccountTree /><div className='ml-1' >Flow</div></ToggleButton>
                        </ToggleButtonGroup>
                        <Button onClick={onCloseDetail}>Close</Button>
                    </Toolbar>
                    <div className='row-center w-full'>
                        {detailView === 'flow' && selectedDiagramData && <FlowWithProvider data={selectedDiagramData} />}
                        {selectedAssay && detailView === 'assay' && <AssayTable assay={selectedAssay} />}
                    </div>
                </Box>
            </Modal>
        </div>
    </ThemeProvider>
}
