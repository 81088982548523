import React, { useState, useEffect } from 'react';
import { Routes, Route, Navigate } from "react-router-dom";
import { LinkBar, LinkSubBar } from '../components';
import { useConfig } from '../hooks';
import Prices from '../pages/Price';

export const Price = () => {
    const { price: options } = useConfig()
    const [tab, setTab] = useState()
    useEffect(() => {
        if (options) setTab(options[0])
    }, [options])
    const onSelectTab = event => {
        setTab(options.find(option => option.name === event))
    }
    return (
        <div>
            <LinkBar options={options} callback={onSelectTab} />
            {tab && tab.children && tab.children.length > 0 && <LinkSubBar category={tab.name} options={tab.children} />}
            {options && <Routes>
                {options.map(({ type, name, path, component }) => {
                    const Component = Prices[component]
                    return <Route key={`key_route_price_${name}`} path={path.replace(`/${type}`, '')} element={<Component />} />
                })}
                <Route path="*" element={<Navigate replace to={options.length > 0 ? options[0].path.replace(':type', options[0].children[0].name) : '/'} />} />
            </Routes>}
        </div>
    )
}