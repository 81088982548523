import React, { useEffect, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import moment from 'moment'
import { gridHeight, numberCommaFormatter } from '../../services/Api';
import { TwoLineHeader } from './CftcGrid';

export const EiaGrid = ({ param, callback }) => {
    const [gridApi, setGridApi] = useState();
    const [rowData, setRowData] = useState([])
    const [columnDefs, setColumnDefs] = useState([])

    const onGridReady = (params) => {
        setGridApi(params.api);
        sizeToFit();
        window.onresizable = () => {
            sizeToFit();
        }
    };
  
    const sizeToFit = () => {
        if (gridApi) gridApi.sizeColumnsToFit();
    };

    useEffect(() => {
        const { data, colDef } = param
        if (data && colDef) {
            const cellRenderer = (col) => {
                return col ? col === 'name' ? param => <div className={`ml-${(param.value.match(/--/g) || []).length * 2} underline blue`}>{param.value.replace(/--/g, '')}</div> 
                : param => <><div className="cell-liner">{numberCommaFormatter(param.value)}</div>
                <div className="cell-liner">{param.data[`${col}_diff`]}</div>
                <div className="cell-liner">{param.data[`${col}_diffpercent`]}</div></> : undefined
            }
            setColumnDefs(colDef.map(({ cellrdr, chart, ...rest }) => ({ cellRenderer: cellRenderer(cellrdr), onCellClicked: param => onClickCallback(param), ...rest })))
            setRowData(data)
        }
    }, [param])
    
    const onClickCallback = (param) => {
        callback(param)
    }
    
    return (
        <div className="column-center w-full">
            <div className="ag-theme-alpine ag-packed-right" style={{ height: gridHeight(param.data.length, 70), width: '99%' }}>
                <AgGridReact rowData={rowData} columnDefs={columnDefs} rowHeight={70} onGridReady={onGridReady} 
                    frameworkComponents={{ TwoLineHeader }}
                    defaultColDef={{
                        flex: 1,
                        minWidth: 60,
                        wrapText: true,
                        suppressMovable: true,
                        headerClass: (params) => {
                            return params.colDef.field === 'name' ? 'ag-header-center' : ''
                        }
                    }}
                />
            </div>
        </div>
    );
};