import { useState, useReducer } from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import HttpsRedirect from 'react-https-redirect';
import Cookies from 'js-cookie'
import { Confirm, Dashboard, News, Price, Snapshot, Model, Profile, Qrcode, Conversation, Upload, CrudeRank, FlowHeat } from './tabs';
import { Layout, Login, Loading } from './layout';
import { GlobalMessage } from './components'
import { StateContext, DispatchContext, initialState, reducer } from './reducers'
import './styles/index.scss';

const App = () => {
    
    const [state, dispatch] = useReducer(reducer, initialState)
    const [authed] = useState(Cookies.get('token') ? true : false)

    if (!authed) return (
        <HttpsRedirect>
            <StateContext.Provider value={state} >
                <DispatchContext.Provider value={dispatch} >
                    <Loading spin={state.spin}/>
                    <Router>
                        <Routes>
                            <Route path="/confirm/:code" element={<Confirm />} />
                            <Route path="*" element={<Login />} />
                        </Routes>
                    </Router>
                </DispatchContext.Provider>
            </StateContext.Provider>
        </HttpsRedirect>
    )

    if (authed && ['/qr-code'].includes(window?.location.pathname))
        return (
          <HttpsRedirect>
             <StateContext.Provider value={state} >
                <DispatchContext.Provider value={dispatch} >
                    <Router>
                        <GlobalMessage newMessage={state.message} newSeverity={state.severity} newDuration={state.duration} />
                        <Routes>
                            <Route path="/qr-code" element={<Qrcode />} />
                        </Routes>
                    </Router>
                </DispatchContext.Provider>
            </StateContext.Provider>
          </HttpsRedirect>
        )
    
    return (
        <HttpsRedirect>
            <StateContext.Provider value={state} >
                <DispatchContext.Provider value={dispatch} >
                    <Router>
                        <Layout>
                            <Loading spin={state.spin}/>
                            <GlobalMessage newMessage={state.message} newSeverity={state.severity} newDuration={state.duration} />
                            <Routes>
                                <Route path="/news/*" element={<News />} />
                                <Route path="/price/*" element={<Price />} />
                                <Route path="/snapshot/*" element={<Snapshot />} />
                                <Route path="/model/*" element={<Model />} />
                                <Route path="/profile/*" element={<Profile />} />
                                <Route path="/conversation" element={<Conversation />} />
                                <Route path="/upload/*" element={<Upload />} />
                                <Route path="/confirm" element={<Confirm />} />
                                <Route path="*" element={<Dashboard />} />
                            </Routes>
                        </Layout>
                    </Router>
                </DispatchContext.Provider>
            </StateContext.Provider>
        </HttpsRedirect>
    )
};

export default App;
