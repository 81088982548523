import { useState, useEffect } from 'react';
import { Backdrop } from '@mui/material'; 
import { Icon } from '../components';
import { useUrl } from '../hooks';


const icons = [
    { label: 'News', icon: 'faNewspaper' },
    { label: 'Price', icon: 'faChartLine' },
    { label: 'Snapshot', icon: 'faEye' },
    { label: 'Model', icon: 'faChartBar' },
    { label: 'Profile', icon: 'faFolder' },
    { label: 'Home', icon: 'faHome' }
]

export const Loading = ({ spin = true }) => {
    const url = useUrl()
    const [index, setIndex] = useState(0)
    
    useEffect(() => {
        const { param_0 } = url
        const tab = icons.find(i => param_0 === i.label.toLowerCase())
        if(tab)
            setIndex(icons.indexOf(tab))
        else
            setIndex(5)

    }, [url])
    return spin && 
    <Backdrop className="z9999 white" open={spin}>
        <div className="containerSpinner">
            <div className="spinnerActual">
                <div className='jump dark_blue'>
                    <div className='centralize'>
                        <div><Icon icon={icons[index].icon} size={'2x'} color='#33478e' /></div>
                        <div className='bold'>{icons[index].label}</div>
                    </div>
                </div>
            </div>
            <div className="spinnerActual overlay"></div>

        </div>
    </Backdrop>
}