import React, { useEffect, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { gridHeight } from '../../services/Api';

export const WindowDealGrid = ({ data }) => {
    const [gridApi, setGridApi] = useState(null);
    const [rowData, setRowData] = useState(data)
    const [columnDefs] = useState([
        { field: 'product', headerName: 'Product', flex: 2, minWidth: 100, valueFormatter: param => param.data.divider ? param.data.value : param.value },
        { field: 'seller_code', headerName: 'Seller' },
        { field: 'buyer_code', headerName: 'Buyer' },
        { field: 'volume_text', headerName: 'Vol', minWidth: 50 },
        { field: 'start_end', headerName: 'Load/Del' },
        { field: 'price_text', headerName: 'Price', flex: 2, minWidth: 150 },
    ])

    const onGridReady = (params) => {
        setGridApi(params.api);
        sizeToFit();
        window.onresizable = () => {
            sizeToFit();
        }
    };
  
    const sizeToFit = () => {
        if (gridApi) gridApi.sizeColumnsToFit();
    };

    useEffect(() => {
        setRowData(data)
    }, [data])
    
    return (
       <div className="ag-theme-alpine ag-packed" style={{ height: gridHeight(rowData.length, 40, 48, rowData.filter(row => row.divider).length * (-15) + 17), width: '99%', minWidth: 300 }}>
           <AgGridReact rowData={rowData} columnDefs={columnDefs} onGridReady={onGridReady} getRowHeight={param => param.data.divider ? 25 : 40 }
                defaultColDef={{
                    resizable: true,
                    flex: 1,
                    minWidth: 70,
                    suppressMovable: true,
                    cellStyle: param => param.data.divider ?  { textAlign: 'center', backgroundColor: param.data.type === 'date' ? '#3d3933' : 'grey', color: 'white', alignItems: 'center', display: 'flex', paddingLeft: 10 } :  { textAlign: 'center' }
                }}
           />
       </div>
    )
}