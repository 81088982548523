import Cookies from 'js-cookie'
import { createAuth0Client } from '@auth0/auth0-spa-js';
import moment from 'moment'
import { AUTH0_AUDIENCE, AUTH0_CLIENT_ID, AUTH0_DOMAIN, APIURL } from '../config'

let TTS_URL, TTS_KEY, TTS_VOICE
const selfUrl = window.location.origin
const changePasswordUrl = `https://${AUTH0_DOMAIN}/dbconnections/change_password`
const ttsBody = `<speak version='1.0' xml:lang='en-US'><voice xml:lang='en-US' xml:gender='Female' name='{{voice}}'>{{text}}</voice></speak>`

let auth0
createAuth0Client({
    domain: AUTH0_DOMAIN,
    clientId:  AUTH0_CLIENT_ID,
    authorizationParams: {
        redirect_uri: window.location.origin,
        audience: AUTH0_AUDIENCE
    }
}).then(res => auth0 = res)

export const defaultTimeFormatter = datetime => {
    if (!datetime) {
        return ''
    } else if (moment(datetime).format('YYYY-MM-DD') === moment().format('YYYY-MM-DD')) {
        return moment(datetime).format('HH:mm')
    } else {
        return moment(datetime).format('MMM DD')
    }
}

export const numberCommaFormatter = num => {
    return num ? num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : num === 0 ? '0' : ''
}

const urlParam = param => param ? `/${param}` : ''

const urlQuery = query => {
    if (!query) {
        return ''
    } else {
        let url = ''
        Object.entries(query).forEach(([key, value], i) => {
            url = `${url}${i === 0 ? '?' : '&'}${key}=${value}`
        })
        return url
    }
}
export const colors = ['#9cc400', '#338100', '#55a7d2', '#33478e', '#747065', '#3d3933', '#ede9dc']
export const chartHeight = (windowHeight, adjustment=250) => windowHeight < 400+adjustment ? 400 : windowHeight > 750+adjustment ? 500 : windowHeight - adjustment
export const singleChartWidth = windowWidth => windowWidth < 300 ? 300 : windowWidth > 1290 ? 1280 : windowWidth - 10
export const doubleChartWidth = windowWidth => windowWidth < 300 ? 300 : windowWidth >= 960 ? windowWidth / 2 - 20 : windowWidth - 10
export const singleDoubleChartWidth = (windowWidth, isSingle) => isSingle ? singleChartWidth(windowWidth) : doubleChartWidth(windowWidth)
export const gridHeight = (rowCount, rowHeight=42, headerHeight=48, adjustment=13) => rowCount*rowHeight+headerHeight+adjustment

export const forgetPassword = (email) => {
    return fetch(`${selfUrl}/api/public/forgetPassword`, { method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify({ email }) }).then((response) => {
        if (response.ok) {
            return response.json().then(({ data }) => data);
        } else {
            throw new Error(response.statusText);
        }
    })
}


export const post2 = async (path, body) => {
    const token = Cookies.get('token')
    return fetch(`${selfUrl}/api/${path}`, { method: 'POST', headers: { 'Content-Type': 'application/json', authorization: `Bearer ${token}` }, body: JSON.stringify(body) }).then(res => res.json())
}

export const change_password = async () => {
    const email = Cookies.get('username')
    const client_id = AUTH0_CLIENT_ID
    const connection = 'Username-Password-Authentication'
    const body = { email, client_id, connection }
    return fetch(changePasswordUrl, { method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(body) }).then(res => res.text())
}

export const login = async () => {
    try {
        await auth0.loginWithPopup({ audience: AUTH0_AUDIENCE })
        console.log('login success')
    } catch (err) {
        console.log(err)
        return
    }
    const token = await auth0.getTokenSilently({ audience: AUTH0_AUDIENCE })
    Cookies.set('token', token, { expires: 14 })
    const { name, username } = await list2('user')
    Cookies.set('name', name, { expires: 14 })
    Cookies.set('username', username, { expires: 14 })
    window.location.reload();
}

export const logout = async () => {
    Cookies.remove('token')
    await auth0.logout()
    window.location.href = selfUrl
}

export const uploadReport = async ({ file, settings }) => {
    const token = Cookies.get('token')
    const formdata = new FormData();
    formdata.append("file", file);
    formdata.append("settings", JSON.stringify(settings));
    return fetch(`${selfUrl}/api/upload/report`, { method: 'POST', body: formdata, headers: { authorization: `Bearer ${token}` } }).then(res => res.json()).then(({ data }) => data)
}

export const upload2 = async (name, formdata) => {
    const token = Cookies.get('token')
    return fetch(`${selfUrl}/api/upload/${name}`, { method: 'POST', body: formdata, headers: { authorization: `Bearer ${token}` } }).then(res => res.json()).then(({ data }) => data)
}

export const upload = async (name, file) => {
    const token = Cookies.get('token')
    const formdata = new FormData();
    formdata.append("file", file);
    return fetch(`${selfUrl}/api/upload/${name}`, { method: 'POST', body: formdata, headers: { authorization: `Bearer ${token}` } }).then(res => res.json()).then(({ data }) => data)
}

export const upload3 = async (file) => {
    const token = Cookies.get('token')
    const formdata = new FormData();
    formdata.append("purpose", "assistants");
    formdata.append("file", file);
    return fetch(`${selfUrl}/api/ai/file`, { method: 'POST', body: formdata, headers: { authorization: `Bearer ${token}` } }).then(res => res.json()).then(({ data }) => data)
}

export const list1 = async (type, param, query, count = 0) => {
    const token = Cookies.get('token')
    const total = 3, interval = 5000
    return fetch(`${APIURL}/${type}${urlParam(param)}${urlQuery(query)}`, { headers: { authorization: `Bearer ${token}` } })
    .then((response) => {
        if (response.ok) {
            return response.json();
        } else if (response.status === 401) {
            return logout()
        } else {
            throw new Error(response.statusText);
        }
    })
    .then((responseJson) => {
        const { meta, data, ...rest } = responseJson
        return data
    })
    .catch( async error => {
        if (count > total) {
            console.log('calling api fail', error, error.status)
        } else {
            console.log('calling api error', error, error.status)
            console.log(`wait 5s to retry ...`)
            // await sleep(interval)
            count += 1
            console.log(`retry (${count})`)
            return list1(type, param, query, count)
        }
        // return logout()
    });
}

export const list2 = async (type, param, query, count = 0) => {
    const token = Cookies.get('token')
    const total = 3, interval = 5000
    return fetch(`${selfUrl}/api/${type}${urlParam(param)}${urlQuery(query)}`, { headers: { authorization: `Bearer ${token}` } })
    .then((response) => {
        if (response.ok) {
            return response.json();
        } else if (response.status === 401) {
            return logout()
        } else {
            throw new Error(response.statusText);
        }
    })
    .then((responseJson) => {
        const { meta, data, ...rest } = responseJson
        return data
    })
    .catch( async error => {
        if (count > total) {
            console.log('calling api fail', error, error.status)
        } else {
            console.log('calling api error', error, error.status)
            console.log(`wait 5s to retry ...`)
            // await sleep(interval)
            count += 1
            console.log(`retry (${count})`)
            return list2(type, param, query, count)
        }
        // return logout()
    });
}

export const text2Speech = async (text) => {
    if (!TTS_URL || !TTS_KEY || !TTS_VOICE) {
        const data = await list2('tts')
        TTS_KEY = data.TTS_KEY
        TTS_URL = data.TTS_URL
        TTS_VOICE = data.TTS_VOICE
    }
    const ttsHeaders = {
        'Content-Type': 'application/ssml+xml',
        'User-Agent': 'curl',
        'X-Microsoft-OutputFormat': 'audio-16khz-128kbitrate-mono-mp3',
        'Ocp-Apim-Subscription-Key': TTS_KEY,
    }
    return fetch(TTS_URL, { method: 'POST', headers: ttsHeaders, body: ttsBody.replace('{{voice}}', TTS_VOICE).replace('{{text}}', text) })
    .then((response) => {
        if (response.ok) {
            return response.blob().then((blob) => URL.createObjectURL(blob));
        } else {
            console.log(response)
        }
    })
}