import { useEffect, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';

import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import moment from 'moment';
import { numberCommaFormatter } from '../../services/Api';

export const HeatGridNew = ({ data, type, height, callback }) => {
    const [gridColumnApi, setGridColumnApi] = useState(null);
    const [rowData, setRowData] = useState([])
    
    const [columnDefs, setColumnDefs] = useState([])

    const onGridReady = (params) => {
        setGridColumnApi(params.columnApi);
        autoSizeAll()
        window.onresizable = () => {
            autoSizeAll()
        }
    };

    const autoSizeAll = (skipHeader) => {
        if (gridColumnApi) {
            const allColumnIds = [];
            gridColumnApi.getAllColumns().forEach(function (column) {
                allColumnIds.push(column.colId);
            });
            gridColumnApi.autoSizeColumns(allColumnIds, skipHeader);
        }
    };

    useEffect(() => {
        if (data.result) {
            const row = data.result.map(res => ({ name: res.name, origin: res.origin, destination: res.destination, avg: res.avg, delta: res.delta, ...res.data }))
            setRowData(row)
        }
    }, [data])
    
    useEffect(() => {
        if (data.dates) {
            const cellStyle = param => {
                const { avg } = param.data
                const delta = param.value/avg
                if (delta > 2) {
                    return { backgroundColor: '#33691E', textAlign: 'right' }
                } else if (delta > 1.5) {
                    return { backgroundColor: '#689F38', textAlign: 'right' }
                } else if (delta > 1.2) {
                    return { backgroundColor: '#8BC34A', textAlign: 'right' }
                } else if (delta > 1.05) {
                    return { backgroundColor: '#AED581', textAlign: 'right' }
                } else if (delta > 1) {
                    return { backgroundColor: '#DCEDC8', textAlign: 'right' }
                } else if (delta === 1) {
                    return { backgroundColor: '#FFFDE7', textAlign: 'right' }
                } else if (delta > 0.952) {
                    return { backgroundColor: '#FFCCBC', textAlign: 'right' }
                } else if (delta > 0.833) {
                    return { backgroundColor: '#FF8A65', textAlign: 'right' }
                } else if (delta > 0.667) {
                    return { backgroundColor: '#FF5722', textAlign: 'right' }
                } else if (delta > 0.5) {
                    return { backgroundColor: '#E64A19', textAlign: 'right' }
                } else {
                    return { backgroundColor: '#BF360C', textAlign: 'right' }
                }
            } 
            const conf = [
                ...data.dates.slice(-3).map(date => ({ field: date, headerName: moment(date).format('MMMYY'),  cellStyle, valueFormatter: param => param.value ? numberCommaFormatter(Math.round(param.value)) : 0 } )),
                { field: 'avg', headerName: '12MA' },
                { field: 'delta', headerName: '% Δ', valueFormatter: param => `${param.value}%` },        
            ]
            if (data && data.result && data.result[0].name) {
                const newConf = [
                    { 
                        field: 'name', 
                        headerComponentParams: {
                            template: `
                                <div style="text-align:center;width:100%">
                                    ${type === 'import' ? 'Destination' : 'Origin'}
                                    <br />
                                    <small>(velocity chart)</small>
                                </div>
                            `
                        },
                        flex: 2, 
                        minWidth: 100, 
                        cellStyle: { textAlign: 'left', textDecoration: 'underline', color: 'blue' }, 
                        onCellClicked: param => onClickCallback(param) 
                    }, 
                    ...conf
                ]
                setColumnDefs(newConf)
            } else {
                const newConf = [
                    { 
                        field: 'origin', 
                        headerComponentParams: {
                            template: `
                                <div style="text-align:center;width:100%">
                                    Origin
                                    <br />
                                    <small>(velocity chart)</small>
                                </div>
                            `
                        },
                        flex: 2, 
                        minWidth: 100, 
                        cellStyle: { textAlign: 'left', textDecoration: 'underline', color: 'blue' }, 
                        onCellClicked: param => onClickCallback(param) 
                    }, 
                    { 
                        field: 'destination', 
                        headerComponentParams: {
                            template: `
                                <div style="text-align:center;width:100%">
                                    Destination
                                    <br />
                                    <small>(velocity chart)</small>
                                </div>
                            `
                        },
                        flex: 2, 
                        minWidth: 100, 
                        cellStyle: { textAlign: 'left', textDecoration: 'underline', color: 'blue' } , 
                        onCellClicked: param => onClickCallback(param) 
                    }, 
                    ...conf
                ]
                setColumnDefs(newConf)
            }
        }
    }, [data, type])

    const onClickCallback = (param) => {
        callback(param)
    }
    
    return (
        <div className="ag-theme-alpine ag-packed" style={{ height, width: '99%', minWidth: 300 }}>
            <AgGridReact rowData={rowData} columnDefs={columnDefs} onGridReady={onGridReady}
                defaultColDef={{
                    resizable: true,
                    flex: 1,
                    minWidth: 50,
                    suppressMovable: true,
                    cellStyle: { textAlign: 'right' }
                }} 
            />
        </div>
    );
};