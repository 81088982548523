import { useState, useEffect } from 'react'
import { useNavigate } from "react-router-dom";
import { Icon } from '../components'
import { useUrl } from '../hooks';

const options = [
    { name: 'news', label: 'news', icon: 'faNewspaper' },
    { name: 'price', label: 'price', icon: 'faChartLine' },
    { name: 'snapshot', label: 'snapshot', icon: 'faEye' },
    { name: 'model', label: 'model', icon: 'faChartBar' },
    { name: 'profile', label: 'profile', icon: 'faFolder' },
]

export const Footer = () => {

    const [selected, setSelected] = useState('news')
    const navigate  = useNavigate()
    const url = useUrl()
    
    useEffect(() => {
        const { param_0 } = url
        setSelected(param_0)
    }, [url])
    const onSelect = name => {
        const { param_0 } = url
        if (param_0 !== name) {
            navigate (`/${name}`)
        }
    }
    return (
        <footer className={`footer`}>
            {options.map(option => 
                <div key={`key_footer_item_${option.name}`} className={`footer__item${selected === option.name ? '__selected' : ''}${['iPhone', 'iPad'].includes(window.navigator.platform) ? '__ios' : ''}`} onClick={() => onSelect(option.name)} >
                    <Icon icon={option.icon} size='lg' />
                    <label className='footer__item__label'>{option.label}</label>
                </div>)}
        </footer>
    )
}